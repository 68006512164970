import star from '../images/star.png'

export const Toast = ({ scrollReached }) => {
  let top = '430%'
  if (window.innerWidth < 1025 && window.innerWidth >= 460) {
    top = '420%'
  }
  if (window.innerWidth < 460) {
    top = '780%'
  }

  return (
    <div style={{ top }} className={`toast ${scrollReached ? 'scroll-reached' : ''}`}>
      <div className='toast-text'>
        <div>
          {'Czy chciałbyś nagrać filmik i pochwalić'}<br />
          {'się swoimi osiągnięciami w niszczeniu'}<br />
          {'przedmiotów znajomym?'}
        </div>
        <div>
          {'Extra, bo u nas otrzymasz darmowe nagranie'}<br />
          {'całej akcji w nagrodę za pierwszą wizytę.'}
        </div>
      </div>
      <img src={star} alt='star' />
    </div>
  )
}