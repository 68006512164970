import polaroid1 from '../images/polaroid1.png';
import fluffySmile from '../images/fluffySmile.png';
import polaroid2 from '../images/polaroid2.png';


export const About = () => {
    return (
        <div id='about' className='about'>
            <div className='about-title'>
                {'O nas'}
            </div>
            <div className='about-container'>
                <div className='about-container-box'>
                    <img src={polaroid1} alt='polaroid1'></img>
                    <div>
                        <div>
                            {'💥 Smash and Fun '} <br />
                            {'to nowoczesne centrum rozrywki 🎉,'} <br />
                            {'w którym możesz rozładować emocje 😜'}<br />
                            {'i otrzymać ⚡ zastrzyk adrenaliny.'}
                        </div>
                    </div>
                </div>
                <div className='about-container-box'>
                    <img src={fluffySmile} alt='polaroid1'></img>
                    <div>
                        <div>{'Tutaj możesz rozbijać 🔥 meble,'}<br />
                            {'urządzenia elektroniczne 📺 i inne ⏰'}<br />
                            {'przedmioty, nie szkodząc sobie 🦺'}<br />
                            {'ani otoczeniu.'}
                        </div>
                    </div>
                </div>
                <div className='about-container-box'>
                    <img src={polaroid2} alt='polaroid1'></img>
                    <div>
                        <div>{'To radosna 😁 metoda przełamania 😵‍💫'}<br />
                            {'stresu i złości. Przeżyjesz nowe 😱'}<br />
                            {'doznania, przekraczające granice 🌪️'}<br />
                            {'tradycyjnych form 🎈 rozrywki.'}<br />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}