import { useState } from 'react';


export const Switch = () => {

    const [active, setActive] = useState('')

    const routeChange = () => {
        setActive('active')
        setTimeout(() => {
            window.location.assign('https://widget.zarezerwuj.pl/direct/6703f4d3-6783-4153-8661-9b7de84bcab8');
        }, 500)
        if(active === 'active') {
            setActive('')
        }
    }
    return (
        <div className="switch" >
            <label className="switch-container">
                <input type="checkbox" />
                <span className={`slider round ${active}`} onClick={routeChange}>
                    <div>{'Zarezerwuj termin'}</div>
                </span>
            </label>
        </div>
    )
};
