import logoFooter from '../images/logoFooter.png';
import heart from '../images/heart.png';
import Statute from '../docs/statute.pdf';
import Policy from '../docs/policy.pdf';
import { Facebook } from '../icons/Facebook';
import { Instagram } from '../icons/Instagram';
import { Tiktok } from '../icons/Tiktok';


export const Footer = () => {
    return (
        <footer id="contact" className='footer'>
            <div className="footer-left">
                <div>
                    <img src={logoFooter} className="footer-left-logo" alt="footer-logo" />
                </div>
                <div className="footer-left-icons-container">
                    <div>
                        <span>
                            <a id='facebook-icon' href="https://www.facebook.com/people/smashandfun/100090772427682/" target="_blank">
                                <Facebook />
                            </a>
                        </span>
                        <span>
                            <a id='instagram-icon' href="https://instagram.com/smashandfun?igshid=YmMyMTA2M2Y=" target="_blank">
                                <Instagram />
                            </a>
                        </span>
                        <span>
                            <a id='tiktok-icon' href="https://www.tiktok.com/@smashandfun" target="_blank">
                                <Tiktok />
                            </a>
                        </span>
                    </div>
                </div>
            </div>
            <div className="footer-middle">
                <div className="footer-middle-title">{'Kontakt'}</div>
                <div className="footer-middle-contact">
                    <div>
                        <a href="mailto:hello@smashandfun.pl">
                            {'hello@smashandfun.pl'}
                        </a>
                    </div>
                    <div>
                        <a href="tel:+48 577 612 893">
                            {'+48 577 612 893'}
                        </a>
                    </div>
                </div>
                <div className="footer-middle-location">
                    <div>
                        <a href='https://goo.gl/maps/eHWrsPV5PZ3iRAky8?coh=178571&entry=tt' target="_blank">
                            {'Goławicka 1a, Warszawa'}
                        </a>
                    </div>
                    <div>{'Pn-Pt 14:00 - 21:00'}</div>
                    <div>{'Sb-Nd 11:00 - 21:00, Wtorek wolne'}</div>
                    <div className="footer-right-policies-mobile">
                        <div>
                            <a href={Statute}>
                                {'Regulamin'}
                            </a>
                        </div>
                        <div>
                            <a href={Policy}>
                                {'Polityka prywatności'}
                            </a>
                        </div>
                    </div>
                </div>
            </div>
            <div className="footer-right">
                <img src={heart} className="footer-right-heart" alt="footer-heart" />
                <div className="footer-right-policies">
                    <div>
                        <a href={Statute}>
                            {'Regulamin'}
                        </a>
                    </div>
                    <div>
                        <a href={Policy}>
                            {'Polityka prywatności'}
                        </a>
                    </div>
                </div>
            </div>
        </footer>
    )
}