import fluffy1 from '../images/fluffy1.png';
import fluffy2 from '../images/fluffy2.png';
import hammer from '../images/hammer.png';
import gamepad from '../images/gamepad.png';
import soap from '../images/soap.png';
import flame from '../images/flame.png';
import { useEffect, useState } from 'react';


export const Offer = ({ scrollReached, moveFluffy }) => {
    const [val, setVal] = useState(0)

    useEffect(() => {

        setVal(moveFluffy / 10)

    }, [moveFluffy])
    return (
        <div id='offer' className='offer'>
            <div className='offer-title'>
                {'Pakiety i ceny'}
            </div>
            <div className='offer-container'>
                <div className='offer-container-box'>
                    <div className='offer-container-box-title'>{'BUŁKA Z MASŁEM 😏'}</div>
                    <div className='offer-container-box-text'>
                        {'30 szklanych przedmiotów.'}<br />
                        {'Narzędzia do demolowania.'}<br />
                        {'Ubranie, kask, rękawice.'}
                    </div>
                    <div className='offer-container-box-details'>
                        <div className='offer-container-box-details-left'>
                            <div>{'1-2 osoby'}</div>
                            <div>{'30 min'}</div>
                        </div>
                        <div className='offer-container-box-details-right'>{'199 zł 💰'}</div>
                    </div>
                </div>
                <div className='offer-container-box'>
                    <div className='offer-container-box-title'>{'ŁATWY 😎'}</div>
                    <div className='offer-container-box-text'>
                        {'25 szklanych przedmiotów,'}<br />
                        {'2 meble, 3 sprzęty RTV i AGD.'}<br />
                        {'Narzędzia do demolowania.'}<br />
                        {'Ubranie, kask, rękawice.'}
                    </div>
                    <div className='offer-container-box-details'>
                        <div className='offer-container-box-details-left'>
                            <div>{'1-2 osoby'}</div>
                            <div>{'45 min'}</div>
                        </div>
                        <div className='offer-container-box-details-right'>{'299 zł 💰'}</div>
                    </div>
                </div>
                <div className='offer-container-box'>
                    <div className='offer-container-box-title'>{'ŚREDNI 😈'}</div>
                    <div className='offer-container-box-text'>
                        {'25 szklanych przedmiotów,'}<br />
                        {'3 meble, 5 sprzętów RTV i AGD.'}<br />
                        {'Narzędzia do demolowania.'}<br />
                        {'Ubranie, kask, rękawice.'}
                    </div>
                    <div className='offer-container-box-details'>
                        <div className='offer-container-box-details-left'>
                            <div>{'1-4 osoby'}</div>
                            <div>{'120 min'}</div>
                        </div>
                        <div className='offer-container-box-details-right'>{'499 zł 💰'}</div>
                    </div>
                </div>
                <div className='offer-container-box'>
                    <div className='offer-container-box-title'>{'TRUDNY 😡'}</div>
                    <div className='offer-container-box-text'>
                        {'35 szklanych przedmiotów, 5 mebli,'}<br />
                        {'8 sprzętów RTV i AGD, 10 mniejszych'}<br />
                        {'urządzeń RTV, AGD. Narzędzia'}<br />
                        {'do demolowania.'}<br />
                        {'Ubranie, kask, rękawice.'}
                    </div>
                    <div className='offer-container-box-details'>
                        <div className='offer-container-box-details-left'>
                            <div>{'1-6 osób'}</div>
                            <div>{'180 min'}</div>
                        </div>
                        <div className='offer-container-box-details-right'>{'999 zł 💰'}</div>
                    </div>

                </div>
            </div>
            <div className='offer-additional-services'>
                <div className='offer-additional-services-title'>
                    {'*Dodatkowo do wybranego pakietu możesz dokupić przedmioty z listy:'}
                </div>
                <div className='offer-additional-services-container'>
                    <div className='offer-additional-services-items'>
                        <div className='offer-additional-services-item'>
                            <div>{'10 szklanych przedmiotów - '}<span>{'50 Zł'}</span></div>
                            <div>{'Drukarka - '}<span>{'50 Zł'}</span></div>
                        </div>
                    </div>
                    <div className='offer-additional-services-items'>
                        <div className='offer-additional-services-item'>
                            <div>{'Klawiatura - '}<span>{'20 Zł'}</span></div>
                            <div>{'Mysz komputerowa - '}<span>{'10 Zł'}</span></div>
                        </div>
                    </div>
                    <div className='offer-additional-services-items'>
                        <div className='offer-additional-services-item'>
                            <div>{'TV/monitor - '}<span>{'100 Zł'}</span></div>
                            <div>{'Telefon - '}<span>{'30 Zł'}</span></div>
                        </div>
                    </div>
                    <div className='offer-additional-services-items'>
                        <div className='offer-additional-services-item'>
                            <div>{'Meble - '}<span>{'120 Zł'}</span></div>
                            <div>{'Nagranie za pomocą GoPro - '}<span>{'50 Zł'}</span></div>
                        </div>
                    </div>

                    <div className='offer-additional-services-items-mobile'>
                        <div className='offer-additional-services-item'>
                            <div>{'Klawiatura - '}<span>{'20 Zł'}</span></div>
                            <div>{'Mysz komputerowa - '}<span>{'10 Zł'}</span></div>
                            <div>{'10 szklanych przedmiotów - '}<span>{'50 Zł'}</span></div>
                            <div>{'Nagranie za pomocą GoPro - '}<span>{'50 Zł'}</span></div>

                        </div>
                    </div>
                    <div className='offer-additional-services-items-mobile'>
                        <div className='offer-additional-services-item'>
                            <div>{'Meble - '}<span>{'120 Zł'}</span></div>
                            <div>{'Drukarka - '}<span>{'50 Zł'}</span></div>
                            <div>{'TV/monitor - '}<span>{'100 Zł'}</span></div>
                            <div>{'Telefon - '}<span>{'30 Zł'}</span></div>
                        </div>
                    </div>
                    <div className='offer-additional-services-flame'>
                        <img src={flame} alt='flame' />
                    </div>
                </div>
            </div>
            <div>
                <div className="offer-button-container">
                    <a href='https://widget.zarezerwuj.pl/direct/6703f4d3-6783-4153-8661-9b7de84bcab8'>
                        <button className="offer-button">{'Zarezerwuj termin'}</button>
                    </a>
                </div>
            </div>
            <div className='offer-fluffy-right'>
                <img style={{ transform: `translateX(${val})` }} src={fluffy2} alt='fluffy2' />
            </div>
            <div className='offer-facilities'>
                <div className='offer-facilities-item'>
                    <img src={hammer} alt='' />
                    <div>
                        {'Nasz personel dostarczy Ci ubrania ochronne, kaski'}<br />
                        {'i rękawice, które są zawarte w cenie, udzieli'}<br />
                        {'instrukcji oraz pomoże w wyborze narzędzi.'}
                    </div>
                </div>
                <div className='offer-facilities-item'>
                    <img src={gamepad} alt='' />
                    <div>
                        {'Zrelaksuj się w naszym kąciku wypoczynkowym'}<br />
                        {'z konsolą PlayStation.'}
                    </div>
                </div>
                <div className='offer-facilities-item'>
                    <img src={soap} alt='' />
                    <div>
                        {'Zatroszczymy się także o wszelkie niezbędne środki'}<br />
                        {'higieniczne dla Twojego komfortu.'}
                    </div>
                </div>
            </div>
            <div className='offer-fluffy-left'>
                <img src={fluffy1} alt='fluffy1' />
            </div>

        </div>
    )
}