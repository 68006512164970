import { useEffect, useState, useRef } from 'react'
import { Menu } from '../icons/Menu';
import logo from '../images/logo.png'


function useClickOutside(containerRef, callback) {
    const callbackRef = useRef();

    useEffect(() => {
        callbackRef.current = callback;
    }, [callback]);

    useEffect(() => {
        function handleClickOutside(event) {
            if (containerRef.current && !containerRef.current.contains(event.target)) {
                callbackRef.current();
            }
        }

        document.addEventListener('touchstart', handleClickOutside);

        return () => {
            document.removeEventListener('touchstart', handleClickOutside);
        };
    }, [containerRef]);
}

export const Header = ({ handleClickScroll }) => {
    const [mobile, setMobile] = useState(false);
    const [isOpen, setIsOpen] = useState(false);
    const containerRef = useRef(null);

    const toggleMenu = () => {
        setIsOpen(true);
    };

    const closeMenu = () => {
        setIsOpen(false);
    };

    useEffect(() => {
        if (window.innerWidth <= 460) {
            setMobile(true)
        }
    }, [mobile]);

      
      useClickOutside(containerRef, closeMenu);
    return (
        <header className="header">
            <img src={logo} className="header-logo" alt="logo" />
            {!mobile && <div className="header-menu">
                <div id='menu-about' className="header-menu-item" onClick={handleClickScroll}>{'O nas'}</div>
                <div id='menu-offer' className="header-menu-item" onClick={handleClickScroll}>{'Pakiety i ceny'}</div>
                <div id='menu-service' className="header-menu-item" onClick={handleClickScroll}>{'Usługi'}</div>
                <div id='menu-contact' className="header-menu-item" onClick={handleClickScroll}>{'Kontakt'}</div>
            </div>}
            <div>
                <button className="header-button">
                    <a href="tel:+48 577 612 893">
                        {'Zadzwoń'}
                    </a>
                </button>
            </div>
            {mobile &&
                <div className='header-icon' onClick={toggleMenu} onTouchStart={toggleMenu}>
                    <Menu />
                </div>
            }
            {
                isOpen && mobile && <div className='header-menu-mobile' ref={containerRef}>
                    <div id='menu-about' className="header-menu-item" onTouchStart={handleClickScroll}>{'O nas'}</div>
                    <div id='menu-offer' className="header-menu-item" onTouchStart={handleClickScroll}>{'Pakiety i ceny'}</div>
                    <div id='menu-service' className="header-menu-item" onTouchStart={handleClickScroll}>{'Usługi'}</div>
                    <div id='menu-contact' className="header-menu-item" onTouchStart={handleClickScroll} >{'Kontakt'}</div>

                </div>
            }
        </header>
    )
}