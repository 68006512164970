import { useState } from "react";
import service1 from '../images/service1.png'
import service2 from '../images/service2.png'
import service3 from '../images/service3.png'
import { ArrowLeft } from "../icons/ArrowLeft";
import { ArrowRight } from "../icons/ArrowRight";

export const Service = () => {
    const [currentIndex, setCurrentIndex] = useState(0);
    const [active, setActive] = useState('active');

    const firstSlideDescription = () => {
        return (
            <>
                {'Szukasz idealnego miejsca na imprezę?'}<br />
                {'Marzysz o zniszczeniu biura czy całego mieszkania?'}<br />
                {'Odwiedź nas! Zorganizujemy całe wydarzenie,'}<br />
                {'a Tobie pozostanie tylko świetna zabawa!'}
            </>
        )
    }

    const secondSlideDescription = () => {
        return (
            <>
                {'Voucher o wartości dowolnej kwoty lub pakiet.'}<br />
                {'Ważny przez 6 miesięcy. Zwrotowi nie podlega.'}<br />
                {'Łączenie z innymi promocjami lub rabatami nie jest możliwe.'}
            </>
        )
    }

    const thirdSlideDescription = () => {
        return (
            <>
                {'Możesz także skorzystać z opcji wynajmu naszego'}<br />
                {'miejsca na swoją imprezę, przyjęcie czy inną okazję.'}
            </>
        )
    }


    const data = [
        { id: 0, src: service1, title: 'Organizacja imprez', description: firstSlideDescription() },
        { id: 1, src: service2, title: 'Bon podarunkowy', description: secondSlideDescription() },
        { id: 2, src: service3, title: 'Wynajem przestrzeni', description: thirdSlideDescription() }
    ]


    const handleClickLeft = () => {
        setActive('')
        setCurrentIndex((prevIndex) => (prevIndex === 0 ? data.length - 1 : prevIndex - 1));
        setTimeout(() => {
            setActive('active')
        }, 500)

    };

    const handleClickRight = () => {
        setActive('')
        setCurrentIndex((prevIndex) => (prevIndex === data.length - 1 ? 0 : prevIndex + 1));
        setTimeout(() => {
            setActive('active')
        }, 500)
    };

    const [touchStart, setTouchStart] = useState(null)
    const [touchEnd, setTouchEnd] = useState(null)
    
    // the required distance between touchStart and touchEnd to be detected as a swipe
    const minSwipeDistance = 50 
    
    const onTouchStart = (e) => {
      setTouchEnd(null) // otherwise the swipe is fired even with usual touch events
      setTouchStart(e.targetTouches[0].clientX)
    }
    
    const onTouchMove = (e) => setTouchEnd(e.targetTouches[0].clientX)
    
    const onTouchEnd = () => {
      if (!touchStart || !touchEnd) return
      const distance = touchStart - touchEnd
      const isLeftSwipe = distance > minSwipeDistance
      const isRightSwipe = distance < -minSwipeDistance
        if(isLeftSwipe) {
            setActive('')
            setCurrentIndex((prevIndex) => (prevIndex === 0 ? data.length - 1 : prevIndex - 1));
            setTimeout(() => {
                setActive('active')
            }, 500)
        }
        if(isRightSwipe) {
            setActive('')
            setCurrentIndex((prevIndex) => (prevIndex === data.length - 1 ? 0 : prevIndex + 1));
            setTimeout(() => {
                setActive('active')
            }, 500)
        }
    }

    return (
        <div id='service' className="service">
            <div className="service-title">{'Usługi'}</div>
            <div className="service-slider" onTouchStart={onTouchStart} onTouchMove={onTouchMove} onTouchEnd={onTouchEnd}>
                <div className={`service-slider-image-container-mobile ${active}`}>
                    <div className="service-slider-image-wrapper-mobile">
                        <img src={data[currentIndex].src}  alt='service'/>
                    </div>
                </div>
                <div className={`service-slider-info ${active}`}>
                    <div className="service-slider-text">
                        <div className="service-slider-text-title">{data[currentIndex].title}</div>
                        <div className="service-slider-text-decription">{data[currentIndex].description}</div>
                        <a href="tel:+48 577 612 893">
                            <button>{'Skontaktuj sie z nami'}</button>
                        </a>
                    </div>

                </div>
                <div className={`service-slider-image-container ${active}`}>
                    <div className="service-slider-image-wrapper">
                        <img src={data[currentIndex].src} alt='service-mobile'/>
                    </div>
                </div>
            </div>
            <div className="service-slider-button-container">
                <div>
                    <button onClick={handleClickLeft} >
                        <ArrowLeft />
                    </button>
                </div>
                <div>
                    <button onClick={handleClickRight}>
                        <ArrowRight />
                    </button>
                </div>
            </div>
        </div>
    )
}